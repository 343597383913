<template>
  <div id="app">
    <Home />
  </div>
</template>

<script>
import Home from './views/index.vue'

export default {
  name: 'App',
  components: {
    Home
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
}
</style>
