const en = {
    index: {
        install: 'install',
        name: "BYEX",
        webTitle: 'Please use your mobile phone to scan the QR code to download',
        webTitle2:'Please open in Safari browser',
        download: 'download',
        ratings: 'ratings',
        age: 'age',
        complaint: 'Complaint',
        newFunction: 'What\'s New',
        version: 'Version',
        introduction: 'Application Introduction',
        rates: 'Ratings and Reviews',
        outof: 'Out of 5',
        information: 'Information',
        seller: 'Seller',
        size: 'Size',
        compatibility: 'Compatibility',
        languages: 'Languages',
        ageRating: 'Age Rating',
        price: 'Price',
        free: 'Free',
        age18: '18+',
        langs: 'English & 9 more',
        ios: 'IOS 9.0+',
        appPrivacy: 'App Privacy',
        disclaim: 'Disclaimer: This website only provides developers with App download and installation hosting. The content and operation-related matters in the App are the responsibility of the App developer and have nothing to do with this website.',
        app: {
            info: 'Welcome to BYEX , one of the world’s leading digital asset exchanges! Trade over 1100 cryptocurrencies including Bitcoin(BTC), Ethereum(ETH), Dogecoin(DOGE), MX Token(MX), etc.. As a World Leading Crypto exchange, BYEX is known for being the first to launch quality projects and meme coins, BYEX  provides professional and secure digital assets trading and management services trusted by over 7 million users worldwide.',
            newf: 'This release includes bug fixes and performance improvements.'
        },
        company: 'BYEX Pro',
        copyright: '© 2021 BYEX  Ltd.',
        copyLink:  'Copy',
        copySuccess:'Copy Success',
        systemTip: 'Due to 17 system limitations, the description file has been downloaded. Please manually go to [Settings]-[General]-[Device Management] and click Install'

    }


}


export default en;
