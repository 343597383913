import Vue from 'vue'
import VueI18n from 'vue-i18n'
import enUS from 'vant/es/locale/lang/en-US';
import zhCN from 'vant/es/locale/lang/zh-CN';
import { Locale } from 'vant';
import langZh from "@/assets/lang/zh.js"
import langEN from "@/assets/lang/en.js"

Vue.use(VueI18n)


let local = localStorage.getItem('locale');

if(!local){
  const jsSrc = (navigator.language || navigator.browserLanguage).toLowerCase();
  if (jsSrc.indexOf('zh') >= 0) {

    local = local || 'zh-cn';
  } else if (jsSrc.indexOf('en') >= 0) {

    local = local || 'en-us';
  } else {

    local = local || 'en-us';
  }

}

Locale.use(local, local=='en-us'?enUS:zhCN);

const i18n = new VueI18n({
  locale: local,
  messages: {
    'zh-cn': {...langZh, ...zhCN},
    'en-us': {...langEN, ...enUS}
  }
})


export default i18n
