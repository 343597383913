import Vue from 'vue'
import App from './App.vue'
import 'lib-flexible'
import i18n from "@/i18n";
import VueClipboard from 'vue-clipboard2'
Vue.config.productionTip = false
Vue.use(VueClipboard)
new Vue({
    i18n,
    render: h => h(App),
}).$mount('#app')

